<footer class="bg-primary text-white">

  <div class="container px-5 px-lg-0 py-6 text-center">

    <div class="d-flex justify-content-between">

      <a [routerLink]="['/']"><img src="assets/images/logo-footer.svg"></a>

      <div class="d-flex align-items-center gap-4">

        <div class="d-none d-lg-flex fw-medium">Suivez-nous</div>

        <a target="_blank" href="https://www.instagram.com/les_palettes_urbaines"><img src="assets/images/instagram.svg"></a>

        <a target="_blank" href="https://www.linkedin.com/showcase/les-palettes-urbaines"><img src="assets/images/linkedin.svg"></a>

      </div>

    </div>

    <hr class="bg-secondary-3">

    <div class="d-lg-flex justify-content-lg-between w-100 pt-4">

      <div class="d-flex flex-column text-start gap-2 ps-7 ps-lg-0 mb-2 mb-lg-0">

        <div class="h6 text-secondary fw-semi-bold ff-archia">Informations</div>

        <a [routerLink]="['/qui-sommes-nous']" class="text-white text-decoration-none">Qui sommes-nous</a>

        <a [routerLink]="['/demande-information']" class="text-white text-decoration-none">Contactez-nous</a>

        <a [routerLink]="['/demande-information/recrutement']" class="text-white text-decoration-none">Recrutement</a>

        <a [routerLink]="['/faq']" class="text-white text-decoration-none">FAQ</a>

      </div>

      <hr class="bg-white opacity-75 mb-4">

      <div class="d-flex flex-column text-start gap-2 ps-7 ps-lg-0 mb-2 mb-lg-0">

        <div class="h6 text-secondary fw-semi-bold ff-archia mb-3">Nos solutions</div>

        <a [routerLink]="['/nos-solutions/espace']" class="text-white text-decoration-none">Solution Espace</a>

        <a [routerLink]="['/nos-solutions/logistique']" class="text-white text-decoration-none">Solution Logistique</a>

      </div>

      <hr class="bg-white opacity-75 mb-4">

      <div class="d-flex flex-column text-start gap-2 ps-7 ps-lg-0 mb-2 mb-lg-0">

        <div class="h6 text-secondary fw-semi-bold ff-archia mb-3">Nos agences</div>

        <a *ngFor="let agence of agences" [routerLink]="['/agence/'+agence.slug]" class="text-white text-decoration-none">{{agence.nom}}</a>

      </div>

      <hr class="bg-white opacity-75 mb-4">

      <div class="d-flex flex-column text-start gap-2 ps-7 ps-lg-0 mb-6 mb-lg-0">

        <div class="h6 text-secondary fw-semi-bold ff-archia mb-3">Le groupe Sogaris</div>

        <a href="https://www.sogaris.fr/manifeste/" class="text-white text-decoration-none">Le manifeste</a>

        <a href="https://www.sogaris.fr/engagements/" class="text-white text-decoration-none">Les engagements</a>

        <a href="https://www.sogaris.fr/rejoignez-le-reseau/" class="text-white text-decoration-none">Le réseau</a>

        <a href="https://www.sogaris.fr/gouvernance/" class="text-white text-decoration-none">La gouvernance</a>

      </div>

      <div class="d-flex justify-content-end mb-6">

        <div><img src="assets/images/logos-footer.png"></div>

      </div>

    </div>

    <div class="mb-3">

      <a [routerLink]="['/mentions-legales']"
         class="text-decoration-none text-white ps-3">Mentions
        légales</a>

    </div>

    <small>Copyright &copy;{{currentYear}} Les Palettes Urbaines.</small>

  </div>

</footer>
