<header class="bg-white fixed-top shadow-sm px-2 px-md-4 px-xxl-7 py-5 d-flex align-items-center">

  <div class="d-flex gap-4 gap-md-5 align-items-center">
    <ng-container *ngIf="isLanding===false">
      <div (click)="headerService.toggleMenu()" class="d-xxl-none cursor-pointer"><i class="far fa-bars text-primary fa-2x"></i> </div>
    </ng-container>
    <a [routerLink]="['/']" class="d-inline-block">
      <img height="42" width="auto" src="assets/images/logo.svg" alt="Sogaris Services">
    </a>
    <ng-container *ngIf="isLanding===false">
      <a [routerLink]="['/nos-solutions']" [class.active]="currentRoute=='/nos-solutions'" class="d-none d-xxl-inline-block header-nav fs-5 fw-medium text-decoration-none">Nos solutions</a>
      <a [routerLink]="['/agences']" [class.active]="currentRoute=='/agences'" class="d-none d-xxl-inline-block header-nav fs-5 fw-medium text-decoration-none">Nos agences</a>
      <a [routerLink]="['/annonces']" [class.active]="currentRoute=='/annonces'" class="d-none d-xxl-inline-block header-nav fs-5 fw-medium text-decoration-none">Nos annonces</a>
      <a [routerLink]="['/qui-sommes-nous']" [class.active]="currentRoute=='/qui-sommes-nous'" class="d-none d-xxl-inline-block header-nav fs-5 fw-medium text-decoration-none">Qui sommes-nous ?</a>
    </ng-container>
  </div>

  <div class="flex-grow-1 d-flex justify-content-end">
    <ng-container *ngIf="isLanding===false">
      <div class="d-flex gap-5 align-items-center">
        <div class="d-none d-lg-block">
          <a [routerLink]="['/demande-information']" class="btn btn-primary btn-width-header">Nous contacter</a>
        </div>
        <!--<div class="d-none d-md-block">
          <a [routerLink]="['/configurateur']" class="btn btn-secondary btn-width-header text-primary">Configurer votre solution</a>
        </div>-->
        <div *ngIf="!authentictionService?.currentUser?.value">
          <a [routerLink]="['/connexion']" class="btn btn-link"><i class="fas fa-user text-secondary-3 fa-2x"></i></a>
        </div>
        <div *ngIf="authentictionService?.currentUser?.value && authentictionService?.currentUser?.value?.category==='prospect'">
          <i [matMenuTriggerFor]="prospectMenu" class="fas fa-user  text-secondary-3 fa-2x cursor-pointer"></i>
          <mat-menu #prospectMenu="matMenu">
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/profil']">Mon profil</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/demande-information']">Créer une demande d'information</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/service-logistique']">Créer une demande logistique</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/configurateur']">Créer une demande de devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-devis']">Vos devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-contrats-et-factures']">Vos contrats et factures</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-services-logistiques']">Vos demandes logistiques</a>
            <div class="text-decoration-none text-dark" mat-menu-item (click)="authentictionService.logout()">Se déconnecter</div>
          </mat-menu>
        </div>
        <div *ngIf="authentictionService?.currentUser?.value && authentictionService?.currentUser?.value?.category==='client'">
          <i [matMenuTriggerFor]="clientMenu" class="fas fa-user text-secondary-3 fa-2x cursor-pointer"></i>
          <mat-menu #clientMenu="matMenu">
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/profil']">Mon profil</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/demande-information']">Créer une demande d'information</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/service-logistique']">Créer une demande logistique</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/configurateur']">Créer une demande de devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-devis']">Vos devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-contrats-et-factures']">Vos contrats et factures</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/liste-services-logistiques']">Vos demandes logistiques</a>
            <div class="text-decoration-none text-dark" mat-menu-item (click)="authentictionService.logout()">Se déconnecter</div>
          </mat-menu>
        </div>
        <div *ngIf="authentictionService?.currentUser?.value && authentictionService?.currentUser?.value?.category==='staff'">
          <i [matMenuTriggerFor]="staffMenu" class="fas fa-user-cog  text-secondary-3 fa-2x cursor-pointer"></i>
          <mat-menu #staffMenu="matMenu">
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/configurateur']">Créer un devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/staff/liste-devis']">Liste des devis</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/staff/liste-clients']">Liste des clients</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/staff/liste-demandes-informations']">Liste des demandes d'informations</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/staff/liste-demandes-logistiques']">Liste des demandes logistiques</a>
            <a class="text-decoration-none text-dark" mat-menu-item [routerLink]="['/staff/liste-annonces']">Liste des annonces</a>
            <div class="text-decoration-none text-dark" mat-menu-item (click)="authentictionService.logout()">Se déconnecter</div>
          </mat-menu>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLanding===true">
      <a href="tel:+33670067859" class="text-decoration-none fw-bold fs-4">06 70 06 78 59 <i class="fas fa-mobile-android-alt ms-2 lh-sm"></i></a>
    </ng-container>
  </div>

</header>

<!--MENU MOBILE-->

<div *ngIf="showMenu" class="bg-white d-flex flex-column mobile-menu full-height w-100 p-4">

  <a [routerLink]="['/nos-solutions']" class="header-nav fs-5 fw-medium text-decoration-none ps-0">Nos solutions</a>
  <a [routerLink]="['/agences']" class="header-nav fs-5 fw-medium text-decoration-none ps-0">Nos agences</a>
  <a [routerLink]="['/annonces']" class="header-nav fs-5 fw-medium text-decoration-none ps-0">Nos annonces</a>
  <a [routerLink]="['/qui-sommes-nous']" class="header-nav fs-5 fw-medium text-decoration-none ps-0 mb-3">Qui sommes-nous ?</a>

  <div class="mb-3">
    <a [routerLink]="['/demande-information']" class="btn btn-primary btn-width-header">Nous contacter</a>
  </div>

  <!--
  <div>
    <a [routerLink]="['/configurateur']" class="btn btn-secondary btn-width-header text-primary">Configurer votre solution</a>
  </div>
  -->

</div>
