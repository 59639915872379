import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {StaffGuard} from './core/guards/staff.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/pages/home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: 'agences',
    loadChildren: () => import('./modules/pages/agencies/agencies.module')
      .then(mod => mod.AgenciesModule)
  },
  {
    path: 'annonces',
    loadChildren: () => import('./modules/pages/annonces/annonces.module')
      .then(mod => mod.AnnoncesModule)
  },
  {
    path: 'annonce/:id',
    loadChildren: () => import('./modules/pages/annonce/annonce.module')
      .then(mod => mod.AnnonceModule)
  },
  {
    path: 'agence/:slug',
    loadChildren: () => import('./modules/pages/agency/agency.module')
      .then(mod => mod.AgencyModule)
  },
  {
    path: 'nos-solutions',
    loadChildren: () => import('./modules/pages/solutions/solutions.module')
      .then(mod => mod.SolutionsModule)
  },
  {
    path: 'nos-solutions/:slug',
    loadChildren: () => import('./modules/pages/solutions/solutions.module')
      .then(mod => mod.SolutionsModule)
  },
  {
    path: 'qui-sommes-nous',
    loadChildren: () => import('./modules/pages/about/about.module')
      .then(mod => mod.AboutModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/pages/faq/faq.module')
      .then(mod => mod.FaqModule)
  },
  {
    path: 'mentions-legales',
    loadChildren: () => import('./modules/pages/legal/legal.module')
      .then(mod => mod.LegalModule)
  },
  {
    path: 'connexion',
    loadChildren: () => import('./modules/pages/login/login.module')
      .then(mod => mod.LoginModule)
  },
  {
    path: 'inscription',
    loadChildren: () => import('./modules/pages/register/register.module')
      .then(mod => mod.RegisterModule)
  },
  {
    path: 'inscription/:slug',
    loadChildren: () => import('./modules/pages/register/register.module')
      .then(mod => mod.RegisterModule)
  },
  {
    path: 'demande-reinitialisation-du-mot-de-passe',
    loadChildren: () => import('./modules/pages/password-reset-request/password-reset-request.module')
      .then(mod => mod.PasswordResetRequestModule)
  },
  {
    path: 'reinitialisation-du-mot-de-passe',
    loadChildren: () => import('./modules/pages/password-reset-confirm/password-reset-confirm.module')
      .then(mod => mod.PasswordResetConfirmModule)
  },
  {
    path: 'demande-information',
    loadChildren: () => import('./modules/pages/demande-information/demande-information.module')
      .then(mod => mod.DemandeInformationModule)
  },
  {
    path: 'landing_espace',
    loadChildren: () => import('./modules/pages/landing/landing.module')
      .then(mod => mod.LandingModule)
  },
  {
    path: 'demande-information/:slug',
    loadChildren: () => import('./modules/pages/demande-information/demande-information.module')
      .then(mod => mod.DemandeInformationModule)
  },
  {
    path: 'demande-information/:slug/:id',
    loadChildren: () => import('./modules/pages/demande-information/demande-information.module')
      .then(mod => mod.DemandeInformationModule)
  },
  {
    path: 'service-logistique', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/service-logistique/service-logistique.module')
      .then(mod => mod.ServiceLogistiqueModule)
  },
  {
    path: 'profil', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/profil/profil.module')
      .then(mod => mod.ProfilModule)
  },
  {
    path: 'configurateur',
    loadChildren: () => import('./modules/pages/devis-creation/devis-creation.module')
      .then(mod => mod.DevisCreationModule)
  },
  {
    path: 'configurateur/:slug',
    loadChildren: () => import('./modules/pages/devis-creation/devis-creation.module')
      .then(mod => mod.DevisCreationModule)
  },
  {
    path: 'configurateur/:slug/:slug2',
    loadChildren: () => import('./modules/pages/devis-creation/devis-creation.module')
      .then(mod => mod.DevisCreationModule)
  },
  {
    path: 'liste-devis', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/deviss/deviss.module')
      .then(mod => mod.DevissModule)
  },
  {
    path: 'devis/:id', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/devis/devis.module')
      .then(mod => mod.DevisModule)
  },
  {
    path: 'liste-contrats-et-factures', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/contrats-factures/contrats-factures.module')
      .then(mod => mod.ContratsFacturesModule)
  },
  {
    path: 'liste-services-logistiques', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/pages/services-logistiques/services-logistiques.module')
      .then(mod => mod.ServicesLogistiquesModule)
  },
  {
    path: 'staff/liste-devis', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-deviss/staff-deviss.module')
      .then(mod => mod.StaffDevissModule)
  },
  {
    path: 'staff/liste-annonces', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-annonces/staff-annonces.module')
      .then(mod => mod.StaffAnnoncesModule)
  },
  {
    path: 'staff/edition-devis/:id', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-devis-edition/staff-devis-edition.module')
      .then(mod => mod.StaffDevisEditionModule)
  },
  {
    path: 'staff/liste-clients', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-clients/staff-clients.module')
      .then(mod => mod.StaffClientsModule)
  },
  {
    path: 'staff/edition-client/:id', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-client-edition/staff-client-edition.module')
      .then(mod => mod.StaffClientEditionModule)
  },
  {
    path: 'staff/nouvelle-annonce', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-annonce-edition/staff-annonce-edition.module')
      .then(mod => mod.StaffAnnonceEditionModule)
  },
  {
    path: 'staff/edition-annonce/:id', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-annonce-edition/staff-annonce-edition.module')
      .then(mod => mod.StaffAnnonceEditionModule)
  },
  {
    path: 'staff/liste-demandes-informations', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-demandes-informations/staff-demandes-informations.module')
      .then(mod => mod.StaffDemandesInformationsModule)
  },
  {
    path: 'staff/liste-demandes-logistiques', canActivate: [StaffGuard],
    loadChildren: () => import('./modules/pages/staff-services-logistiques/staff-services-logistiques.module')
      .then(mod => mod.StaffServicesLogistiquesModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
